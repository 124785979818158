/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import Link from 'next/link';

import {
  DontHaveAccText,
  DontHaveAccTextWrapper,
  MainTitle,
  OrBlockLine,
  OrLineWrapper,
  OrText,
  RememberMeText,
  RememberMeWrapper,
  SignUpText,
  SubTitle,
} from './style';
import { loginSchema } from './signin.schema';
import Input from '../Shared/Input/Input';
import Button from '../Shared/Button/Button';
import Checkbox from '../Shared/Checkbox/Checkbox';
import { ISignInForm } from '../../util/types';
import { userActions } from '@pickles/shared/redux/actions';
import { ISignIn } from '@pickles/shared/utils/types';
import withSigninSignupComposer, { IWithSigninSignup } from '../../hocs/withSigninSignupComposer';
import { useTranslation } from 'react-i18next';
import { UserHooks, LoaderHooks } from '../../app/redux/hooks';
import Loading from '../Shared/Loading';
import { LoadingWrapper } from '../SearchContent/style';

const SigninContent: FC<IWithSigninSignup> = ({ setIsSignup }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const isLoading = LoaderHooks.useLoadingIndicator('login');

  useEffect(() => {
    setIsSignup(false);
  }, []);

  const useLoginUserCallback = (dispatch: any) =>
    useCallback(
      ({ email, password }: ISignIn) => dispatch(userActions.loginUser(email, password)),
      [dispatch],
    );

  const userAccount = UserHooks.useUserAccount();
  const loginHandler = useLoginUserCallback(dispatch);

  const {
    values: { email, password, rememberMe },
    errors: { email: errorEmail, password: errorPass },
    handleChange,
    handleSubmit,
  } = useFormik<ISignInForm>({
    validationSchema: loginSchema,
    initialValues: {
      email: '',
      password: '',
      rememberMe: false,
    },
    onSubmit: (value: ISignInForm) => {
      if (email.length && password.length) {
        loginHandler(value);
      }
    },
  });

  useEffect(() => {
    if (userAccount !== null) {
      router.push('/');
    }
  }, [userAccount]);

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <div>
      <MainTitle
        mobileTextAlign={'start'}
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '370px',
        }}
      >
        {t('labels:welcome')}
      </MainTitle>
      <SubTitle
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '370px',
        }}
        mobileTextAlign={'start'}
      >
        {t('labels:sign_in')}
      </SubTitle>
      <form
        action=""
        onSubmit={handleSubmit}
        style={{
          margin: '0 auto',
          textAlign: 'center',
          width: '370px',
        }}
      >
        <Input
          onChange={handleChange}
          borderRadius={8}
          title={t('labels:email_address')}
          placeholder={t('fields:email_address')}
          // type='email'
          width="370px"
          iconType="email"
          value={email}
          id="email"
          marginBottom={30}
          name="email"
          error={errorEmail}
        />
        <Input
          isPassword
          hasForgotPassword
          onChange={handleChange}
          borderRadius={8}
          title={t('labels:password')}
          placeholder={t('fields:password')}
          type="password"
          width="370px"
          iconType="password"
          value={password}
          id="password"
          marginBottom={30}
          name="password"
          error={errorPass}
        />
        {
          // TODO: save user login session, the checkbox currently does not do anything
          /* <RememberMeWrapper>
          <Checkbox
            isChecked={rememberMe}
            handleChecked={handleChange}
            id="rememberMe"
            name="rememberMe"
          />
          <RememberMeText>{t('labels:remember_me')}</RememberMeText>
        </RememberMeWrapper> */
        }
        <Button
          hasArrow
          text={t('buttons:sign_in')}
          height="52"
          marginBottom="28"
          type="submit"
          id="sign_in"
          onBtnClick={handleSubmit}
          disabled={!!errorEmail || !!errorPass}
        />
      </form>
      <OrLineWrapper>
        <OrBlockLine />
        <OrText>{t('labels:or')}</OrText>
        <OrBlockLine />
      </OrLineWrapper>
      <DontHaveAccTextWrapper>
        <DontHaveAccText>{t('labels:dont_have_account')}</DontHaveAccText>
        <Link href="/signUp" passHref>
          <SignUpText id="sign_up">{t('buttons:sign_up')}</SignUpText>
        </Link>
      </DontHaveAccTextWrapper>
    </div>
  );
};

export default withSigninSignupComposer(SigninContent);
