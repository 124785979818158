import {
  FILTER_OBJECT_AUCTION_CATEGORY,
  FILTER_OBJECT_AUCTION_TYPE,
} from '@pickles/shared/models/general.types';
import { dashboardActions } from '@pickles/shared/redux/actions';
import { eventCategoriesToLabels, eventTypesArrayToLabels } from '@pickles/shared/utils/helpers';
import _ from 'lodash';
import Image from 'next/image';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppHooks, DashboardHooks, LoaderHooks } from '../../app/redux/hooks';
import useScreenSize from '../../hooks/useScreenSize';
import DropdownIcon from '../../public/icons/dropDownArrowIcon.svg';
import AuctionCard from '../AuctionCard';
import MetaTags from '../Metatags';
import EmptyContentComponent from '../Shared/EmptyContent';
import SelectDropDown, { SELECT_MODE } from '../Shared/ItemFilterHolder/SelectDropDown';
import Loading from '../Shared/Loading';
import MainTextComponent from '../Shared/MainTextComponent/MainTextComponent';

import { useRouter } from 'next/router';
import { LoadingWrapper } from '../SearchContent/style';
import {
  AuctionGridWrapper,
  AuctionsContentWrapper,
  AuctionsText,
  AuctionTypePicker,
  AuctionTypePickerWrapper,
  ImageWrapper,
  ItemTypePicker,
  TypePickerWrapper,
} from './style';

export const AuctionsListing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const sortedAuctions = DashboardHooks.useSortedAuctions();
  const selectedAuctionsFilter = DashboardHooks.useAuctionsFilter();
  const isLoading = LoaderHooks.useLoadingIndicator('dashboard');
  const now = AppHooks.useApplicationTimer();
  const { maxWidth } = useScreenSize();

  const [isAuctionTypeOpen, setIsActionTypeOpen] = useState(false);
  const [isAuctionCategoryOpen, setIsAuctionCategoryOpen] = useState(false);

  const selectedAuctionTypes = useMemo(() => {
    return eventTypesArrayToLabels(selectedAuctionsFilter.eventType);
  }, [selectedAuctionsFilter.eventType]);

  const selectedAuctionCategories = useMemo(() => {
    return eventCategoriesToLabels(selectedAuctionsFilter.eventCategory);
  }, [selectedAuctionsFilter.eventCategory]);

  const auctionTypeSelectRef = useRef(null);
  const itemTypeSelectRef = useRef(null);

  const auctions = useMemo(() => {
    return _.map(sortedAuctions, (auction) => <AuctionCard key={auction.id} auction={auction} />);
  }, [sortedAuctions]);

  useEffect(() => {
    const itemType = router.query.item_type;

    if (itemType) {
      const selectedOptions = _.isArray(itemType)
        ? itemType.map((itemType) => itemType.toUpperCase())
        : [itemType.toUpperCase()];
      const availableOptions = FILTER_OBJECT_AUCTION_CATEGORY.map((category) => category.value);
      const intersectOptions = _.intersection(availableOptions, selectedOptions);
      dispatch(
        dashboardActions.setAuctionsFilter(selectedAuctionsFilter.eventType, intersectOptions),
      );
    } else {
      dispatch(dashboardActions.getDashboard());
    }
  }, [router, dispatch]);

  const renderAuctionsListing = () => {
    return (
      <>
        {auctions.length ? (
          <AuctionGridWrapper>{auctions}</AuctionGridWrapper>
        ) : (
          <EmptyContentComponent
            title={t('labels:no_auctions_yet')}
            description={t('labels:no_auctions')}
          />
        )}

        {isLoading && (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        )}
      </>
    );
  };

  return (
    <AuctionsContentWrapper>
      <MetaTags title="Auctions Listing" />
      <MainTextComponent text={t('labels:drawer_auctions')} marginBottom={16} marginTop={32} />
      <TypePickerWrapper>
        {maxWidth.s ? null : <AuctionsText>{sortedAuctions.length} auctions</AuctionsText>}
        <AuctionTypePickerWrapper>
          <AuctionTypePicker
            isActive={isAuctionTypeOpen}
            onClick={() => setIsActionTypeOpen(!isAuctionTypeOpen)}
            id={'auction-type-dropdown'}
          >
            {selectedAuctionTypes || t('fields:select_auction_type_web')}
            <ImageWrapper>
              <Image src={DropdownIcon} alt="Dropdown Icon" />
            </ImageWrapper>
          </AuctionTypePicker>
          {isAuctionTypeOpen && (
            <SelectDropDown
              ref={auctionTypeSelectRef}
              selectedOptions={selectedAuctionsFilter.eventType}
              options={FILTER_OBJECT_AUCTION_TYPE}
              mode={SELECT_MODE.MULTIPLE}
              onClickOutside={setIsActionTypeOpen}
              onClickApply={(selectedOptions: string[]) => {
                dispatch(
                  dashboardActions.setAuctionsFilter(
                    selectedOptions,
                    selectedAuctionsFilter.eventCategory,
                  ),
                );
              }}
            />
          )}
        </AuctionTypePickerWrapper>
        <AuctionTypePickerWrapper>
          <ItemTypePicker
            onClick={() => setIsAuctionCategoryOpen(!isAuctionCategoryOpen)}
            id={'item-type-dropdown'}
          >
            {selectedAuctionCategories || t('fields:select_item_type_web')}
            <ImageWrapper>
              <Image src={DropdownIcon} alt="Dropdown Icon" />
            </ImageWrapper>
          </ItemTypePicker>
          {isAuctionCategoryOpen && (
            <SelectDropDown
              selectedOptions={selectedAuctionsFilter.eventCategory}
              ref={itemTypeSelectRef}
              mode={SELECT_MODE.MULTIPLE}
              options={FILTER_OBJECT_AUCTION_CATEGORY}
              onClickOutside={setIsAuctionCategoryOpen}
              onClickApply={(selectedOptions: string[]) => {
                dispatch(
                  dashboardActions.setAuctionsFilter(
                    selectedAuctionsFilter.eventType,
                    selectedOptions,
                  ),
                );
              }}
            />
          )}
        </AuctionTypePickerWrapper>
      </TypePickerWrapper>
      {renderAuctionsListing()}
    </AuctionsContentWrapper>
  );
};

export default AuctionsListing;
