import config from '@pickles/shared/config';
import { appActions, userActions } from '@pickles/shared/redux/actions';
import { numberToAmountLabel, numberToLocaleString } from '@pickles/shared/utils/helpers';
import Image from 'next/image';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UserHooks } from '../../app/redux/hooks';
import { closeImg, masterCardImg, maybankImg, visaImg } from '../Shared/Icons';
import { FilterTypes } from '../Shared/Select';
import { Select } from '../Shared/Select/Select';
import PaymentMethod from './PaymentMethod';
import {
  CloseIcon,
  TopUpBalanceRow,
  TopUpContainer,
  TopUpDescription,
  TopUpInfoBalance,
  TopUpMethodsText,
  TopUpMethodsCard,
  TopUpRow,
  TopUpSubmit,
  TopUpTitle,
} from '../TopUpModal/styles';

const paymentMethodsObj = [
  { title: 'Maybank2U', name: 'fpx_mb2u', icons: [maybankImg] },
  { title: 'Credit Card', name: 'credit', icons: [visaImg, masterCardImg] },
];

interface TopUpContentProps {
  isMobile: boolean;
  isOpened: boolean;
  closeIconHandler?: () => void;
}

const TopUpContent: FC<TopUpContentProps> = ({ isMobile, isOpened, closeIconHandler }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const [topUpValue, setTopUpValue] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('fpx_mb2u');

  const user = UserHooks.useUserAccount();

  // TODO: Move to shared folder as reusable component as the code logic is similar to mobile app
  const maxDepositAmount = config.MAX_DEPOSIT_AMOUNT || 5000; // MOLPAY allows max limit RM 5000
  const minDepositAmount = config.MIN_DEPOSIT_AMOUNT || 2;
  const isBtnDisabled = topUpValue === 0;

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  }, [isOpened]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const num = +e.target.value.replace(/[^0-9]/g, '');
    setTopUpValue(num);
  };

  const onTopUpPress = () => {
    if (topUpValue >= minDepositAmount && topUpValue <= maxDepositAmount) {
      dispatch(userActions.topUp(`${topUpValue}`, paymentMethod));
    } else {
      const fallbackValue = topUpValue < minDepositAmount ? minDepositAmount : maxDepositAmount;
      setTopUpValue(fallbackValue);

      dispatch(
        appActions.showAlert({
          alertType: 'alert',
          title: 'Pickles',
          message: t('errors:transaction_limit', {
            minAmount: numberToAmountLabel(minDepositAmount),
            maxAmount: numberToAmountLabel(maxDepositAmount),
          }),
        }),
      );
    }
  };

  const renderPaymentMethods = () => {
    return paymentMethodsObj.map((method) => {
      const { title, name, icons } = method;
      const isMethodActive = paymentMethod === name;

      return (
        <PaymentMethod
          key={name}
          activeType={isMethodActive}
          icons={icons}
          paymentMethod={name}
          setPaymentMethod={setPaymentMethod}
          text={title}
        />
      );
    });
  }

  return (
    <TopUpContainer>
      {!isMobile && (
        <CloseIcon onClick={closeIconHandler}>
          <Image src={closeImg} alt="close icon" />
        </CloseIcon>
      )}
      <TopUpTitle>{t('labels:add_money')}</TopUpTitle>
      <TopUpDescription>{t('labels:all_deposits_refundable')}</TopUpDescription>
      <TopUpInfoBalance>
        <TopUpBalanceRow>
          <Select options={['RM']} selectedIndex={0} type={FilterTypes.Select}></Select>
          <input
            type="text"
            ref={inputRef}
            value={numberToLocaleString(topUpValue)}
            onChange={onChange}
            placeholder="0"
          />
        </TopUpBalanceRow>
        <div>
          <div>
            {t('labels:available_balance')}: {numberToAmountLabel(user?.availableBalance)}
          </div>
          <TopUpRow>
            <div>
              {t('labels:blocked_balance_alt')}: {numberToAmountLabel(user?.blockedAmount)}
            </div>
            <div>{t('labels:no_fees')}</div>
          </TopUpRow>
        </div>
      </TopUpInfoBalance>
      <TopUpMethodsText>
        {t('labels:select_payments')}
      </TopUpMethodsText>
      <TopUpMethodsCard>
        {renderPaymentMethods()}
      </TopUpMethodsCard>
      <button id="molpay_btn" hidden />
      <TopUpSubmit onClick={onTopUpPress} type="button" disabled={isBtnDisabled}>
        + {t('labels:top_up_securely')}
      </TopUpSubmit>
    </TopUpContainer>
  );
};

export default TopUpContent;
