import styled from 'styled-components';

interface ITypeWrapper {
  activeType: boolean;
}

export const PaymentTypeWrapper = styled.div<ITypeWrapper>`
  width: 140px;
  height: 70px;
  border: ${({ activeType }) => (activeType ? '2px solid #1C4D8E' : '1px solid #D6DADF')};
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ activeType }) => (activeType ? '#F7FCFF' : 'none')};
`;

export const PaymentTypeContentWrapper = styled.div`
  text-align: center;
`;
